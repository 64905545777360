"use client"; // Error components must be Client Components

import { useEffect } from "react";
import { Button } from "@/components/layout/Button";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <section className="bg-white">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-brand-secondary100">
            500
          </h1>
          <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl">
            Ein Fehler trat auf
          </p>
          <p className="mb-4 text-lg font-light text-gray-500">
            Es tut uns leid, aber irgendetwas ist schief gelaufen.
            <br />
            Wir wurden benachrichtigt und kümmern uns darum.
          </p>
          <Button
            as="button"
            colorScheme="primary"
            className="my-4"
            onClick={
              // Attempt to recover by trying to re-render the segment
              () => reset()
            }
          >
            Erneut versuchen
          </Button>
        </div>
      </div>
    </section>
  );
}
